<!--
 * @Author: Jon
 * @Date: 2020-03-02 15:16:50
 * @LastEditTime: 2020-03-02 16:48:55
 * @LastEditors: Please set LastEditors
 * @Description: Blockchain connection demo
 * @FilePath: /vue-base/vue-base/src/views/bc-demo.vue
 -->

<template>
  <div>
    <h3>Information</h3>
    Your address: {{ address }}
    <br />
    <br />
    Contract address: {{ contractAddress }}
    <br />
    <br />
    Your role: {{ role }}
    <br />
    <br />
    Your Company Name: {{ companyName }}
    <br />
    <br />
    Your Balance: {{ balance }}
    <hr />
    <h3>Register</h3>
    <input v-model="regName" placeholder="Company name" />
    <br />
    <br />
    <label>Supplier</label>
    <input
      type="radio"
      name="role"
      value="1"
      label="Seller"
      v-model="regRole"
    />
    <label>Investor</label>
    <input
      type="radio"
      name="role"
      value="2"
      label="Investor"
      v-model="regRole"
    />
    <label>Brand Owner</label>
    <input
      type="radio"
      name="role"
      value="3"
      label="Brand Owner"
      v-model="regRole"
    />
    <label>Administrator</label>
    <input
      type="radio"
      name="role"
      value="4"
      label="Administrator"
      v-model="regRole"
    />
    <label>Finance Manager</label>
    <input
      type="radio"
      name="role"
      value="5"
      label="Finance Manager"
      v-model="regRole"
    />
    <label>Senior Finance Manager</label>
    <input
      type="radio"
      name="role"
      value="6"
      label="SeniorFinance Manager"
      v-model="regRole"
    />

    <br />
    <br />
    <input type="button" id="btnRole" value="Register" v-on:click="register" />
    <hr />
    <h3>Administer users</h3>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Role</th>
          <th>Approved</th>
          <th>address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, i) in users" :key="i">
          <td>{{ user.name }}</td>
          <td>{{ user.role }}</td>
          <td>{{ user.approved }}</td>
          <td>{{ user.address }}</td>
          <td><button @click="approveUser(user.address)">Approve</button></td>
        </tr>
      </tbody>
    </table>

    <hr />

    <h3>Deposit/Withdraw</h3>
    <label>Amount</label>
    <br />
    <input v-model="exchangeAmount" />
    <br />
    <br />
    <label>Withdraw</label>
    <input
      type="radio"
      name="exchangeType"
      value="1"
      label="Withdraw"
      v-model="exchangeType"
    />
    <label>Deposit</label>
    <input
      type="radio"
      name="exchangeType"
      value="0"
      label="Deposit"
      v-model="exchangeType"
    />
    <br />
    <br />
    <input type="button" value="Submit" id="exchangeSubmitBtn" v-on:click="exchangeClick" />
    <br />
    <hr />

    <h3>Administer Deposit/Withdraw</h3>
    <table>
      <thead>
        <tr>
          <th>Amount</th>
          <th>Type</th>
          <th>Requester</th>
          <th>Senior Finance Manager Approved</th>
          <th>Finance Manager approved</th>
          <th>Paid</th>
          <th>Rejected</th>
          <th>Rejected Rason</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="request in exchange" :key="request.id">
          <td>{{ request.amount }}</td>
          <td>{{ request.direction }}</td>
          <td>{{ request.requester }}</td>
          <td>{{ request.seniorFinanceManagerApproved }}</td>
          <td>{{ request.financeManagerApproved }}</td>
          <td>{{ request.paid }}</td>
          <td>{{ request.rejected }}</td>
          <td>{{ request.rejectionReason }}</td>
          <td><button @click="approveExchangeRequest(request.id)">Approve</button>
          <button @click="rejectExchangeRequest(request.id)">Reject</button></td>

        </tr>
      </tbody>
    </table>

    <hr />

    <h3>Create loan request</h3>
    <label>Ask amount</label>
    <br />
    <input v-model="newAskAmount" />
    <br />
    <br />
    <label>Repay amount</label>
    <br />
    <input v-model="newRepayAmount" />
    <br />
    <br />
    <label>Description</label>
    <br />
    <input v-model="newDescription" />
    <br />
    <br />
    <label>Length (days)</label>
    <br />
    <input v-model="newLength" />
    <br />
    <br />
    <label>Buyer</label>
    <br />
    <input v-model="newBuyer" />
    <br />
    <br />
    <label>Manufacturing Start Date (Unix time)</label>
    <br />
    <input v-model="newManufacturingStartDate" />
    <br />
    <br />
    <label>Financing Purpose</label>
    <br />
    <input v-model="newFinancingPurpose" />
    <br />
    <br />
    <label>Brand Owner</label>
    <br />
    <select v-model="brandOwnerAddress">
      <option
        v-for="user in users"
        v-bind:value="user.address"
        v-bind:key="user.address"
      >
        {{ user.name }}
      </option>
    </select>
    <br />
    <br />
    <label>Extra Data</label>
    <br />
    <textarea v-model="extraData" />
    <br />
    <br />
    <input
      type="button"
      value="Submit"
      id="newSubmitBtn"
      v-on:click="newLoan"
    />
    <hr />
    <h3>Finaince requests</h3>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Supplier</th>
          <th>Ask Amount</th>
          <th>Repay Amount</th>
          <th>Description</th>
          <th>Length</th>
          <th>Buyer</th>
          <th>Funds Sent</th>
          <th>Repaid</th>
          <th>Manufacturing Start Date</th>
          <th>Purpose</th>
          <th>Funding info</th>
          <th>Brand Owner</th>
          <th>Status</th>
          <th>Extra Data</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in financeRequests" :key="i">
          <td>{{ row.id }}</td>
          <td>{{ row.supplier }} {{ row.supplierAddress }}</td>
          <td>{{ row.askAmount }}</td>
          <td>{{ row.repayAmount }}</td>
          <td>{{ row.description }}</td>
          <td>{{ row.length }}</td>
          <td>{{ row.buyer }}</td>
          <td>{{ row.fundsSent }}</td>
          <td>{{ row.repaid }}</td>
          <td>{{ row.manufacturingStartDate }}</td>
          <td>{{ row.purpose }}</td>
          <td>
            <ul v-for="(fi, j) in row.fundsProvided" :key="j">
              <li>{{ fi.financier }} ({{ fi.amount }})</li>
            </ul>
          </td>
          <td>{{ row.brandOwner }}</td>
          <td>{{ row.status }}</td>
          <td>{{ row.extraData }}</td>
          <td>
            <button @click="approveFinanceRequest(row.id)">Approve</button>
            <button @click="rejectFinanceRequest(row.id)">Reject</button>
            <button @click="repayFinanceRequest(row.id)">Repay</button>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr />
    <h3>Fund request</h3>
    <label>Fund request ID</label>
    <br />
    <input v-model="fundRequestId" />
    <br />
    <br />
    <label>Amount</label>
    <br />
    <input v-model="fundAmount" />
    <br />
    <br />
    <input type="button" value="Submit" id="fundSubmitBtn" v-on:click="fund" />
    <br />
    <br />
  </div>
</template>
<script>
import rules from '../plugins/rules';
import Web3 from 'web3';
import {
  getFinanceRequests,
  connect,
  resolveRoleId,
  getUsers,
  getSupplierOverview,
  getExchangeRequests
} from '../utils/blockchain';
export default {
  data() {
    return {
      address: null,
      role: null,
      companyName: null,
      regName: null,
      regRole: null,
      newAskAmount: null,
      newRepayAmount: null,
      newDescription: null,
      newLength: null,
      newBuyer: null,
      newManufacturingStartDate: null,
      newFinancingPurpose: null,
      financeRequests: [],
      fundAmount: null,
      fundRequestId: null,
      contractAddress: null,
      users: null,
      brandOwnerAddress: 0,
      extraData: null,
      exchangeType: "1",
      exchangeAmount: null,
      exchange: null,
      balance: 0,
    };
  },
  methods: {
    async register() {
      await tradeFlowContract.methods
        .registerUser(this.regRole, this.regName)
        .send({ from: this.address });
      this.refreshInfo();
    },
    async refreshInfo() {
      let user = await tradeFlowContract.methods.getUser(this.address).call();
      let roleId = user.role;
      this.role = resolveRoleId(roleId);
      this.companyName = user.name;
      this.financeRequests = await getFinanceRequests(tradeFlowContract);
      this.contractAddress = contractAddress;
      this.users = await getUsers();
      if (this.users.length > 0) {
        this.brandOwnerAddress = this.users[0].address;
      }
      this.exchange = await getExchangeRequests();
      this.balance = web3.utils.fromWei(await tradeFlowContract.methods.balanceOf(this.address).call());
      let root = web3.utils.fromWei(await tradeFlowContract.methods.balanceOf("0x65e023CBB1012298923b2B3FF2FcEb94d46506Be").call());
      console.log(root);
      console.log(web3.eth.getBalance("0x65e023CBB1012298923b2B3FF2FcEb94d46506Be"));
    },
    async newLoan() {
      await tradeFlowContract.methods
        .createFinanceRequest(
          web3.utils.toWei(this.newAskAmount),
          web3.utils.toWei(this.newRepayAmount),
          this.newDescription,
          this.newLength,
          this.newBuyer,
          this.newManufacturingStartDate,
          this.newFinancingPurpose,
          this.brandOwnerAddress,
          this.extraData
        )
        .send({ from: this.address });
      await this.refreshInfo();
    },
    async fund() {
      await tradeFlowContract.methods
        .fund(this.fundRequestId, web3.utils.toWei(this.fundAmount))
        .send({ from: this.address });
      await this.refreshInfo();
    },
    async approveUser(address) {
      await tradeFlowContract.methods.approveUser(address).send({ from: this.address });
      await this.refreshInfo();
    },
    async approveFinanceRequest(i) {
      await tradeFlowContract.methods.approveFinanceRequest(i).send({ from: this.address });
      await this.refreshInfo();
    },
    async rejectFinanceRequest(i) {
      await tradeFlowContract.methods.rejectFinanceRequest(i).send({ from: this.address });
      await this.refreshInfo();
    },
    async repayFinanceRequest(i) {
      await tradeFlowContract.methods.repay(i).send({ from: this.address });
      await this.refreshInfo();
    },
    async exchangeClick() {
      let result = await tradeFlowContract.methods
        .createExchangeRequest(web3.utils.toWei(this.exchangeAmount), this.exchangeType)
        .send({ from: this.address });

      console.log("result", result);
      console.log("id", result.events.ExchangeRequestCreated.returnValues.exchangeRequest.id);
      this.refreshInfo();
    },
    async approveExchangeRequest(id) {
      let result = await tradeFlowContract.methods
        .approveExchangeRequest(id)
        .send({ from: this.address });
      console.log(result);

      this.refreshInfo();
    },
    async rejectExchangeRequest(id) {
      let reason = prompt('What is the reason?');
      await tradeFlowContract.methods
        .rejectExchangeRequest(id, reason)
        .send({ from: this.address });
      this.refreshInfo();
    }
  },
  async mounted() {
    await connect();
    let accounts = await web3.eth.getAccounts();
    this.address = accounts[0];
    this.refreshInfo();
  }
};
</script>
<style lang="scss" scoped>
table {
  border-collapse: collapse;
  border: 1px solid #000;
}
table td,
table th {
  border: 1px solid #000000;
  padding: 5px;
}
</style>
